/* @import url('https://fonts.googleapis.com/css2?family=Handjet:wght@500&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Handjet&family=Protest+Guerrilla&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: sans-serif;
}

.intro * {
  font-family: "Protest Guerrilla";
}

.tabs * {
  font-family: sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #454d56;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #01524e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #01524e;
}

/* styling for icon tag for fb github etc so that it is clickable */
i {
  cursor: pointer;
}
i:hover {
  color: white;
  font-size: 20px;
}

/* loader animation */
.c {
  animation: c 1s infinite;
}
@keyframes c {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}

.f {
  animation: f 1s infinite;
}
@keyframes f {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.l {
  animation: l 1s infinite;
}
@keyframes l {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.header h1 {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Protest Guerrilla";
}

/* input */

input {
  height: 40px;
  border: 1px solid gray;
  width: 100%;
  padding-left: 20px;
}

input:focus {
  outline: none;
  border: 2px solid gray;
}

.link-style {
  color: white;
  text-decoration: underline;
}
.link-style:hover {
  color: blue; /* Change this to your desired hover color */
}
